<template>
  <div class="vx-row mt-6">
    <div :class="vehicleType=='car'?'vehicle-place ':'vehicle-place-motor'" class="vx-col text-center m-auto md:p-4 p-2 md:pb-0 pb-0 text-black border-solid "
      :style="{
      backgroundImage: 'url(\''+ require('@/assets/images/elements/place.jpeg')+'\')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      }"
      >
      <div v-if="vehicleType=='car'">
        <div class="flex text-3xl md:text-4xl lg:text-5xl mb-2 md:mb-0 lg:mb-2 font-bold">
          <div class="w-1/2">{{firstNum}}</div>
          <div class="w-1/2">{{secondNum}}</div>
        </div>
        <div class="text-md md:text-1xl lg:text-2xl w-full">
          {{vehiclePlateProvince}}
        </div>
      </div>
      <div v-else>
        <div class="firstNum text-center text-3xl md:text-4xl lg:text-5xl font-bold">{{firstNum}}</div>
        <div class="text-md md:text-1xl lg:text-2xl w-full">
          {{vehiclePlateProvince}}
        </div>
        <div class="text-center text-3xl md:text-4xl lg:text-5xl font-bold">{{secondNum}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vehicle-place',
  props: {
    firstNum: { type: String },
    secondNum: { type: String },
    vehiclePlateProvince: { type: String },
    vehicleType: { type: String },
  },
  data: ()=>({
    name: ''
  })
}
</script>

<style lang="scss" scoped>
  .vehicle-place-motor {
    font-family: sans-serif;
    height: 170px;
    width: 170px;
    .firstNum {
      line-height: 1.3;
    }
  }
  .vehicle-place {
    font-family: sans-serif;
    height: 120px;
    width: 220px;
  }
  @media (max-width: 1024px) {
    .vehicle-place { height: 120px; width: 220px;}
    .vehicle-place-motor { 
      height: 170px;  width: 170px;}
  }

  @media (max-width: 768px) {
    .vehicle-place { height: 90px; width: 170px;}
    .vehicle-place-motor { 
      height: 140px; 
      width: 140px;
      .firstNum {
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 444px) {
    .vehicle-place { height: 80px; width: 140px;}
    .vehicle-place-motor { height: 120px; width: 120px;}
  }
</style>