<template>
    <div>
      <!-- Filters -->
      <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters">
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full">
            <label class="text-sm opacity-75">Vehicle Type</label>
            <v-select :options="filters.options.vehicleType" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="filters.selected.vehicleType" class="mb-4 md:mb-0" />
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <label class="text-sm opacity-75">EV</label>
            <v-select :options="filters.options.evType" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="filters.selected.evType" class="mb-4 md:mb-0" />
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <label class="text-sm opacity-75">Brands</label>
            <v-select :options="brandOptionWithAll" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="filters.selected.brand" class="mb-4 md:mb-0" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full flex justify-end">
            <vs-button class="md:mt-6 ml-3" color="success" type="border" icon-pack="feather" icon="icon-plus"
              @click="handleNewModel">New Model</vs-button>
          </div>
        </div>
      </vx-card>
  
      <vs-table :sst="true" @search="handleSearch" @sort="handleSort" v-model="selectedRow" :max-items="searching.limit"
        search :data="itemsData">
        <template slot="header">
          <div class="flex-grow">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="
                  p-4
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                ">
                <span class="mr-2">{{ searching.limit }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="paginationSetPageSize(5)">
                  <span>5</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="paginationSetPageSize(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="paginationSetPageSize(30)">
                  <span>30</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="paginationSetPageSize(40)">
                  <span>40</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>
  
        <template slot="thead">
          <vs-th sort-key="id">Brand</vs-th>
          <vs-th sort-key="createdDate">Title</vs-th>
          <vs-th sort-key="total">Title EN</vs-th>
          <vs-th sort-key="serviceId">Priority</vs-th>
          <vs-th class="text-center">isCar</vs-th>
          <vs-th class="text-center">isEV</vs-th>
          <vs-th class="text-center">Status</vs-th>
          <vs-th class="text-center">Action</vs-th>
        </template>
  
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="data[indextr].id" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].id">
              <img :src="data[indextr].brandLogoUrl" alt="" srcset="" class="h-10" />
            </vs-td>
            <vs-td :data="data[indextr].title">
              {{ data[indextr].title }}
            </vs-td>
            <vs-td :data="data[indextr].title_en">
              {{ data[indextr].title_en }}
            </vs-td>
            <vs-td :data="data[indextr].priority">
              {{ data[indextr].priority || 0 }}
            </vs-td>
            <vs-td :data="data[indextr].isCar">
              <vs-checkbox disabled v-model="data[indextr].isCar" @change="handleCheckbox(data[indextr])" />
            </vs-td>
            <vs-td :data="data[indextr].isEV">
              <vs-checkbox disabled v-model="data[indextr].isEV" @change="handleCheckbox(data[indextr])" />
            </vs-td>
            <vs-td :data="data[indextr].priority">
              <vs-chip class="ag-grid-cell-chip" :color="
                data[indextr].isActive
                  ? 'success'
                  : 'warning'
              ">
                <span>{{
                  data[indextr].isActive
                    ? "Active"
                    : "Inactive"
                }}</span>
              </vs-chip>
            </vs-td>
            <vs-td>
              <div class="text-center">
                <feather-icon icon="EditIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer"
                  @click="handleEditRecord(data[indextr])" />
                <!-- <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" /> -->
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
  
      <div class="pt-2 flex justify-between">
        <div>
          <p>
            Page: {{ searching.page }} of {{ totalPagination }} | Total:
            {{ totalRecord }}
          </p>
        </div>
        <div>
          <vs-pagination :total="totalPagination" v-model="searching.page"></vs-pagination>
        </div>
      </div>
  
      <!-- Prompt -->
      <vs-prompt :title="formTitle" color="success" @cancel="resetModelForm()" @close="resetModelForm()"
        @accept="!isEdit ? handleCreate() : handleUpdate()" :is-valid="formValid" :active.sync="popupFormActive">
        <div class="mb-4">
          <label class="text-sm opacity-75">Brands</label>
          <v-select :options="brandOption" placeholder="Please select brand" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="brandSelected" class="mb-4 md:mb-0" />
        </div>
        <div class="mb-4">
          <label class="text-md">Title</label>
          <vs-input size="large" placeholder="Input title" v-model="form.title" class="w-full" />
        </div>
        <div class="mb-2">
          <label class="text-md">Title EN</label>
          <vs-input size="large" placeholder="Input title en" v-model="form.title_en" class="w-full" />
        </div>
        <div class="mb-2">
          <label class="text-md">Priority</label>
          <vs-input size="large" maxlength="3" oninput="this.value = this.value.replace(/[^0-9]/, '').replace(/^(0)/, '')"
            placeholder="Input priority" v-model="form.priority" class="w-full" />
        </div>
        <div class="mb-2">
          <label class="text-md">Vehicle Type</label>
          <vs-checkbox v-model="form.isCar" class="mt-2">Is Car</vs-checkbox>
          <vs-checkbox v-model="form.isEV" class="mt-2">Is EV</vs-checkbox>
        </div>
        <div class="mb-2">
          <label class="text-md">Active / Inactive</label>
          <vs-checkbox v-model="form.isActive" class="mt-2">Is Active</vs-checkbox>
        </div>
      </vs-prompt>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import VehiclePlace from "@/views/pages/information/components/VehiclePlace";
  import CarCard from "@/views/pages/information/components/CarCard";
  import TagCard from "@/views/pages/information/components/TagCard";
  import vSelect from "vue-select";
  import alerts from "@/mixins/alerts";
  
  export default {
    name: "model-list",
    mixins: [alerts],
    components: {
      vSelect,
      CarCard,
      TagCard,
      VehiclePlace,
    },
    data: () => ({
      popupFormActive: false,
      form: {
        title: null,
        title_en: null,
        isCar: true,
        isEV: false,
        priority: 0,
        isActive: true,
      },
      searching: {
        limit: 10,
        page: 1,
        searchAdvance: {},
        sort: "createdAt:desc",
      },
      selectedRow: [],
      log: [],
      filters: {
        selected: {
          vehicleType: { label: "All", value: "all" },
          evType: { label: "All", value: "all" },
          brand: { label: "All", value: "all" },
        },
        options: {
          vehicleType: [
            { label: "All", value: "all" },
            { label: "Car", value: "car-1" },
            { label: "Motorcycle", value: "car-0" },
          ],
          evType: [
            { label: "All", value: "all" },
            { label: "EV", value: "ev" },
            { label: "Engine", value: "engine" },
          ],
        },
      },
      isEdit: false,
      brandSelected: null
    }),
    created() {
      this.fetchModels(this.searching);
      this.fetchBrands({
        searchAdvance: {
          sort: 'priority:asc'
        }
      });
    },
    computed: {
      ...mapGetters({
        models: "masterData/models",
        brandOption: "masterData/brandOption",
      }),
      itemsData() {
        return this.$store.state.masterData.models.rows
          ? JSON.parse(JSON.stringify(this.$store.state.masterData.models.rows))
          : [];
      },
      totalPagination() {
        return +Math.ceil(
          this.$store.state.masterData.models.total / this.searching.limit
        );
      },
      totalRecord() {
        return this.$store.state.masterData.models.total || 0;
      },
      formValid() {
        if (this.brandSelected && this.form.title_en) return true;
        else return false;
      },
      formTitle() {
        return this.isEdit ? "Edit form" : "Add form";
      },
      brandOptionWithAll() {
        return [{ label: 'All', value: '' }, ...this.brandOption]
      },
    },
    watch: {
      itemsData() {
        this.selectedRow = [];
      },
      "searching.page"() {
        this.fetchModels(this.searching);
      },
      "searching.limit"() {
        this.fetchModels(this.searching);
      },
      "filters.selected.evType"() {
        let type = this.filters.selected.evType.value;
        delete this.searching.isEV;
        switch (type) {
          case "ev":
            this.searching.isEV = 1
            break;
          case "engine":
            this.searching.isEV = 0
            break;
          default:
            break;
        }
        this.fetchModels(this.searching);
      },
      "filters.selected.vehicleType"() {
        let status = this.filters.selected.vehicleType.value;
        delete this.searching.isCar;
        delete this.searching.isMotorcycle;
        switch (status) {
          case "car-1":
            this.searching.isCar = 1
            break;
          case "car-0":
            this.searching.isCar = 0
            break;
          default:
            break;
        }
        this.fetchModels(this.searching);
      },
      "filters.selected.brand"() {
        let brandId = this.filters.selected.brand.value;
        delete this.searching.brand_id;
        if (brandId)
          this.searching.brand_id = brandId
        this.fetchModels(this.searching);
      },
    },
    methods: {
      ...mapActions({
        fetchModels: "masterData/getModels",
        createModel: "masterData/createModel",
        updateModel: "masterData/updateModel",
        deleteModel: "masterData/deleteModel",
        fetchBrands: "masterData/getBrands",
      }),
      handleSearch(text) {
        this.searching.page = 1;
        this.searching.searchAdvance = {}
        if (text)
          this.searching.searchAdvance = {
            title: { type: "like", value: text },
            title_en: { type: "like", value: text },
          };
        this.fetchModels(this.searching);
      },
      handleSort(key, active) {
        if (active) {
          this.searching.searchAdvance = {
            sort: `${key}|${active}`,
            ...this.searching.searchAdvance,
          };
          this.fetchModels(this.searching);
        }
      },
      confirmDeleteRecord() { },
      handleEditRecord(item) {
        this.isEdit = true;
        this.brandSelected = { label: item.brandTitleEn, value: item.brand_id }
        this.form = Object.assign(this.form, item);
        this.popupFormActive = true;
      },
      paginationSetPageSize(val) {
        this.searching.limit = val;
      },
      resetColFilters() {
        // Reset Filter Options
        this.filters.selected.status = { label: "All", value: "all" };
        this.filters.selected.service = { label: "All", value: "all" };
        this.$refs.filterCard.removeRefreshAnimation();
      },
      async handleCreate() {
        try {
          this.$vs.loading();
          const res = await this.createModel({
            brand_id: this.brandSelected.value,
            ...this.form
          });
          console.log(res);
          this.showSuccess("Created Model Success");
          this.fetchModels(this.searching);
          this.resetModelForm();
        } catch (error) {
          this.showError(error.message);
        } finally {
          this.$vs.loading.close();
        }
      },
      async handleUpdate() {
        try {
          this.$vs.loading();
          const id = this.form.id;
          delete this.form.id;
          await this.updateModel({
            id,
            brand_id: this.brandSelected.value,
            title: this.form.title,
            title_en: this.form.title_en,
            isCar: this.form.isCar,
            isEV: this.form.isEV,
            priority: this.form.priority,
            isActive: this.form.isActive,
          });
          this.showSuccess("Updated Model Success");
          this.fetchModels(this.searching);
        } catch (error) {
          this.showError(error.message);
        } finally {
          this.resetModelForm();
          this.$vs.loading.close();
        }
      },
      handleCheckbox() {
        // this.updateModel({
        //   id: item.id,
        //   isCar: item.isCar ? 1 : 0,
        //   isMotorcycle: item.isMotorcycle ? 1 : 0,
        // }).then(() => {
        //   this.showSuccess(`Update ${item.title_en} Success`);
        //   this.fetchModels(this.searching);
        // });
      },
      handleNewModel() {
        this.popupFormActive = true;
        this.isEdit = false;
      },
      resetModelForm() {
        this.form.title = null
        this.form.title_en = null
        this.form.isCar = true;
        this.form.isEV = false;
        this.form.priority = 0;
        this.form.isActive = true;
        this.brandSelected = null
      },
    },
  };
  </script>
  
  <style lang="scss" scpoped>
 
  .ag-grid-cell-chip {
    &.vs-chip-success {
      background: rgba(var(--vs-success), 0.15);
      color: rgba(var(--vs-success), 1) !important;
      font-weight: 500;
    }
  
    &.vs-chip-warning {
      background: rgba(var(--vs-warning), 0.15);
      color: rgba(var(--vs-warning), 1) !important;
      font-weight: 500;
    }
  
    &.vs-chip-danger {
      background: rgba(var(--vs-danger), 0.15);
      color: rgba(var(--vs-danger), 1) !important;
      font-weight: 500;
    }
  }
  </style>
  