<template>
  <vx-card class="mb-4 bg-gradient text-white shadow-lg">
    <img :src="symbol" alt="bg-symbol" srcset="" class="bg-symbol">
    <div>
      <h5 class="mb-2 text-white">ชื่อ {{data.firstname}} {{data.lastname}}</h5>
      <p class="text-lg"><b>เลขทะเบียน</b> {{data.vehiclePlateNumber}} {{data.vehiclePlateProvince}} </p>
      <p class="text-lg mt-2"><b>ยี่ห้อ</b> {{data.brand}} <b>รุ่น</b> {{data.model}}</p>
      <div class="flex justify-between mt-5">
        <vs-chip transparent color="dark" class="text-white font-hairline" style="font-weight: 100">
          <vs-avatar :src="symbol" />
          Mamove Car
        </vs-chip>
        <div class="flex" v-if="actionButton">
          <!-- <vs-button class="py-2 px-3 mr-1" size="" color="white" type="border" icon-pack="feather" icon="icon-trash-2" @click="registerId=item.id ,deleteDialogActive=true">ลบ</vs-button> -->
          <vs-button class="py-2 px-3" size="" color="white" type="border" icon-pack="feather" icon="icon-edit" @click="$router.push({path: 'register' , query: {token, id: registerId}})">แก้ไข</vs-button>
        </div>
    </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    token: {
      type: String,
    },
    registerId: {
      type: String,
    },
    actionButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      symbol: require("@/assets/images/elements/car-internet.png"),
    };
  },
};
</script>
<style scoped>
.bg-gradient {
  background: linear-gradient(to right, rgb(9, 36, 74), rgb(74, 142, 239));
}
.bg-symbol {
  width: auto;
  height: 100%;
  position: absolute;
  right: 0;
  opacity: 0.1;
}
.vs-avatar--con-img img {
  width: 90% !important;
  height: 90% !important;
}
</style>
