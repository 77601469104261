export default {
  methods: {
    showSuccess(text) {
      this.$vs.notify({
        color: 'success',
        title: 'Sucess',
        text,
      })
    },
    showError(text) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text,
      })
    },
  },
}
